































import { mediciones_inventario } from "@/shared/dtos/mediciones_inventario";
import { MessageService } from "@/shared/services/message-service";
import mediciones_inventarioModule from "@/store/modules/mediciones_inventario-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
    MedicionesInventarioFormulario: () =>
      import("./mediciones_inventarioFormulario.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class mediciones_inventarioView extends Vue {
  @Prop({ default: -1 }) id_inventario!: number;
  public id_edit: string = "-1";
  public show_dialog = false;
  async created() {
    await mediciones_inventarioModule.getmediciones_inventarios();
  }

  public get id() {
    if (this.id_inventario === -1) {
      return this.$route.params.id;
    } else {
      return this.id_inventario;
    }
  }
  refrescar(a: any) {
    return mediciones_inventarioModule.mediciones_inventarios.filter(
      (x) => x.id_inventario.toString() === this.id.toString()
    );
  }
  nuevo() {
    this.abrir_formulario("-1");
  }

  editar(obj: mediciones_inventario) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  eliminar(obj: mediciones_inventario) {
    mediciones_inventarioModule
      .eliminarmediciones_inventario(new mediciones_inventario(obj))
      .then(() => {
        //@ts-ignore
        this.$refs["data_table"].refresh();
      })
      .catch(() => {
        MessageService.toast(
          "No se ha podido eliminar, hay datos asociados",
          MessageService.TypeWarning
        );
      });
  }
  public cancelar() {}
  abrir_formulario(id: string) {
    this.id_edit = id;
    this.show_dialog = true;
  }
}
