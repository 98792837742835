import { store } from "@/store/store";
import { mediciones_inventario } from "@/shared/dtos/mediciones_inventario";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "mediciones_inventarioModule",
  store,
  dynamic: true,
})
class mediciones_inventarioModule extends VuexModule {
  public mediciones_inventarios: mediciones_inventario[] = [];
  public mediciones_inventario: mediciones_inventario = new mediciones_inventario();

  @Action({ commit: "onGetmediciones_inventarios" })
  public async getmediciones_inventarios() {
    return await ssmHttpService.get(API.mediciones_inventario);
  }

  @Action({ commit: "onGetmediciones_inventario" })
  public async getmediciones_inventario(id: any) {
    return await ssmHttpService.get(API.mediciones_inventario + "/" + id);
  }

  @Action
  public async guardarmediciones_inventario(
    mediciones_inventario: mediciones_inventario
  ) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(
      API.mediciones_inventario,
      mediciones_inventario.toJson()
    );
    this.getmediciones_inventarios();
  }

  @Action
  public async modificarmediciones_inventario(
    mediciones_inventario: mediciones_inventario
  ) {
    await ssmHttpService.put(
      API.mediciones_inventario + "/" + mediciones_inventario.id,
      mediciones_inventario
    );
    this.getmediciones_inventarios();
  }

  @Action
  public async eliminarmediciones_inventario(
    mediciones_inventario: mediciones_inventario
  ) {
    await ssmHttpService.delete(
      API.mediciones_inventario + "/" + mediciones_inventario.id,
      null,
      false
    );
    this.getmediciones_inventarios();
  }

  @Mutation
  public onGetmediciones_inventarios(res: mediciones_inventario[]) {
    this.mediciones_inventarios = res
      ? res.map((x) => new mediciones_inventario(x))
      : [];
  }

  @Mutation
  public onGetmediciones_inventario(res: mediciones_inventario) {
    this.mediciones_inventario = new mediciones_inventario(res);
  }
}
export default getModule(mediciones_inventarioModule);
